import { ServiceToC } from "../Axios/Service";
import qs from "qs";

const PROFILE = {
  order: "VUE_APP_ORDER_PREFIX",
};

function getType(apiType) {
  return process.env[PROFILE[apiType]];
}

class OrderApi {
  constructor(ApiType) {
    this.ApiType = ApiType;
  }

  orderPlanData(orderNumber) {
    let apiType = getType(this.ApiType);
    return ServiceToC({
      url: `/${apiType}/api/orderPlan/orderPlanData`,
      method: "post",
      data: qs.stringify({ orderNumber: orderNumber }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  payRequestDetail(id) {
    let apiType = getType(this.ApiType);
    return ServiceToC({
      url: `/${apiType}/api/procureOrder/payRequestDetail`,
      method: "post",
      data: qs.stringify({ requestNumber: id }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
  selectCompanyBorrowList(companyId) {
    let apiType = getType(this.ApiType);
    return ServiceToC({
      url: `/${apiType}/api/request/selectCompanyBorrowList?companyId=${companyId}`,
      method: "post",
    });
  }


}

export default OrderApi;
