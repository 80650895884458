import { Service, ServiceFile, ServiceToC } from "../Axios/Service";
import qs from "qs";
import store from "@/store/index";

const PROFILE = {
  resource: "VUE_APP_RESOURCE_PREFIX",
};

function getType(apiType) {
  return process.env[PROFILE[apiType]];
}

class ResourceApi {
  constructor(ApiType) {
    this.ApiType = ApiType;
  }

  //推特图
  tweetImage(obj) {
    let apiType = getType(this.ApiType);
    return ServiceToC({
      url: `/${apiType}/platApi/resource/tweetImage`,
      method: "post",
      data: obj,
    });
  }

  //资源列表
  resourceList(obj) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/resource/resourceList`,
      //url:"http://localhost:9500/wintac-spu-web-test-sx/api/resource/resourceList",
      method: "post",
      data: obj,
    });
  }

  //设施静态数据查询
  facStaticList(obj) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/facStatic/list`,
      method: "post",
      data: qs.stringify(obj),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
  //根据parentId查询文件
  queryAllByParentId(obj) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/resourceUpload/queryAllByParentId`,
      method: "post",
      data: qs.stringify(obj),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
  //设施静态数据删除
  removeFac(obj) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/facStatic/remove`,
      method: "post",
      data: qs.stringify(obj),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  //设施静态数据添加
  facStaticAdd(obj) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/facStatic/add`,
      method: "post",
      data: obj,
    });
  }

  //用车巴士信息新增
  addBusInfo(obj) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/trafficBus/add`,
      method: "post",
      data: obj,
    });
  }

  //用车巴士信息删除
  removeBusInfo(obj) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/trafficBus/remove`,
      method: "post",
      data: qs.stringify(obj),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  //用车巴士信息列表
  BusInfoList(obj) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/facStatic/add`,
      method: "post",
      data: obj,
    });
  }

  //周边静态数据查询
  positionList() {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/position/list`,
      method: "post",
    });
  }

  //周边静态数据添加
  positionAdd(uploadObj) {
    let apiType = getType(this.ApiType);
    var formData = new FormData();
    formData.append("file", uploadObj.file);
    formData.append(
      "positionName",
      new Blob([uploadObj.positionName], { type: "application/json" })
    );
    return Service({
      url: `/${apiType}/api/position/add`,
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  //周边静态数据删除
  removePosition(obj) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/position/remove`,
      method: "post",
      data: qs.stringify(obj),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  //产品批量新增
  roomAddList(datas) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `${apiType}/api/resourceProduct/addList`,
      method: "post",
      data: datas,
    });
  }

  //产品新增
  roomAdd(datas) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `${apiType}/api/resourceProduct/add`,
      method: "post",
      data: datas,
    });
  }

  //产品修改
  roomUpdate(datas) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `${apiType}/api/resourceProduct/update`,
      method: "post",
      data: datas,
    });
  }

  //产品列表
  roomList(datas) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `${apiType}/api/resourceProduct/list`,
      method: "post",
      data: datas,
    });
  }

  //国家模糊列表··
  countryList(obj) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/public/countryList`,
      method: "post",
      data: qs.stringify(obj),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  //城市模糊列表··
  cityList(obj) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/public/cityList`,
      //url:"http://localhost:9500/wintac-spu-web-test-sx/api/country/cityList",
      method: "post",
      data: qs.stringify(obj),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  //资源新增
  resourceAdd(obj) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/resource/addResource`,
      //url: "http://localhost:9500/wintac-spu-web-test-sx/api/resource/addResource",
      method: "post",
      data: obj,
    });
  }

  //静态类目新增
  categoryAdd(obj) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/category/add`,
      method: "post",
      data: obj,
    });
  }

  //修改类目新增
  categoryUpdate(obj) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/category/update`,
      method: "post",
      data: obj,
    });
  }

  //静态类目列表
  categoryList(obj) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/category/list`,
      method: "post",
      data: obj,
    });
  }

  //导游子类添加
  addGuideLei(obj) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/categorySon/add`,
      method: "post",
      data: obj,
    });
  }

  //导游子类删除
  delGuideLei(id) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/categorySon/del?id=${id}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  //导游选择的区域删除
  delGuideLeiErea(id) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/resource/areaDel?id=${id}`,
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  //导游子类列表
  GuideLeiList(obj) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/categorySon/list`,
      // url:"http://localhost:9500/wintac-spu-web-test-sx/api/categorySon/list",
      method: "post",
      data: obj,
    });
  }

  //导游子类目服务新增和修改
  addGuideService(obj) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/guideService/add`,
      method: "post",
      data: obj,
    });
  }

  //导游子类目服务列表
  GuideServiceList(obj) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/guideService/listNotPage`,
      method: "post",
      data: obj,
    });
  }

  //导游产品库新增
  AddGuideProduct(obj) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/guideSonLib/add`,
      method: "post",
      data: obj,
    });
  }

  //导游产品库删除
  DeleteGuideProduct(id) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/guideSonLib/del?id=${id}`,
      method: "get",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  //导游产品库列表
  GuideProductList(obj) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/guideSonLib/list`,
      method: "post",
      data: obj,
    });
  }

  resourceDetail(obj) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/resource/resourceDetail`,
      //url:"http://localhost:9500/wintac-spu-web-test-sx/api/resource/resourceDetail",
      method: "post",
      data: qs.stringify(obj),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  contactDel(obj) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/resource/resourceDetail`,
      method: "post",
      data: qs.stringify(obj),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  /**
   * 地点选择四合一列表
   * @param {*} placePageRequest
   */
  placeList(placePageRequest) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/place/placeList`,
      method: "post",
      data: placePageRequest,
    });
  }

  /**
   * 通过大类的Ids获取产品库数据 以及 小类数据 (翻译除外,连接框除外)
   * @param {*} categoryIds  大类ID的集合
   */
  queryPersonSupplierByCategoryIds(categoryIds) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/category/queryPersonSupplierByCategoryIds`,
      method: "post",
      data: categoryIds,
    });
  }

  /**
   * 通过大类的Id和子类ID的集合获取产品库数据 以及 小类数据 (翻译,连接框)张专用
   * @param {*} sonSelectRequest
   */
  queryPersonSupplierBySonSelect(sonSelectRequest) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/category/queryPersonSupplierBySonSelect`,
      method: "post",
      data: sonSelectRequest,
    });
  }

  /**
   * 文件删除
   * @param {*} uid
   */
  delFile(uid) {
    let apiType = getType(this.ApiType);
    return Service({
      url: `/${apiType}/api/resourceUpload/delFile`,
      method: "post",
      data: qs.stringify({ id: uid }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
  }

  /**
   * 多文件上传
   * files 文件对象集合
   * flodName 图片在自己系统所对应的名称
   * parentId 父级ID
   * state 父表状态
   * @param {*} uploadObj
   */
  uploadMultiple(uploadObj) {
    let apiType = getType(this.ApiType);
    var formData = new FormData();
    for (let i = 0; i < uploadObj.files.length; i++) {
      formData.append("files", uploadObj.files[i].raw);
      console.log(uploadObj.files[i]);
    }
    formData.append(
      "flodName",
      new Blob([uploadObj.flodName], { type: "application/json" })
    );
    formData.append(
      "parentId",
      new Blob([uploadObj.parentId], { type: "application/json" })
    );
    formData.append(
      "state",
      new Blob([uploadObj.state], { type: "application/json" })
    );
    return Service({
      url: `${apiType}/api/resourceUpload/uploadMultiple`,
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  /**
   * 通过父级ID的集合查询图片
   * @param picIdArr
   * @returns {*}
   */
  queryPicByParentIds(picIdArr) {
    let apiType = getType(this.ApiType);
    return ServiceToC({
      url: `/${apiType}/api/resourceUpload/queryPicByParentIds`,
      method: "post",
      data: picIdArr,
    });
  }
}

export default ResourceApi;
