var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.datas
    ? _c(
        "div",
        { staticClass: "container" },
        [
          _c("van-nav-bar", { attrs: { title: "应付款审批" } }),
          _c("div", { staticClass: "header" }, [
            _c("div", { staticClass: "mainTop" }, [
              _vm._v(" 本次"),
              _vm.datas.requestType === 3
                ? _c("span", [_vm._v("借款")])
                : _vm._e(),
              _vm._v(
                "提请金额：" +
                  _vm._s(_vm.datas.price) +
                  _vm._s(_vm.datas.currency) +
                  " "
              ),
              _c("span", { staticStyle: { "font-size": "0.2rem" } }, [
                _vm._v(
                  "(" + _vm._s(_vm.numberToChinese(_vm.datas.price)) + ")"
                ),
              ]),
              _vm.datas.currency !== "¥"
                ? _c("p", [
                    _vm._v(
                      "提请原币金额：" +
                        _vm._s(_vm.datas.yuanBiPrice) +
                        _vm._s(_vm.datas.currency) +
                        " "
                    ),
                    _c("span", { staticStyle: { "font-size": "0.2rem" } }, [
                      _vm._v(
                        "(" +
                          _vm._s(_vm.numberToChinese(_vm.datas.yuanBiPrice)) +
                          ")"
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              {
                staticClass: "requestDetail card",
                staticStyle: { "margin-bottom": "0.1rem" },
              },
              [
                _vm.datas.id
                  ? _c("p", { staticClass: "requestDetail_p1" }, [
                      _vm._v("提请编号：" + _vm._s(_vm.datas.id)),
                    ])
                  : _vm._e(),
                _vm.datas.createName
                  ? _c("p", { staticClass: "requestDetail_p1" }, [
                      _vm._v("提请人：" + _vm._s(_vm.datas.createName)),
                    ])
                  : _vm._e(),
                _vm.datas.companyName
                  ? _c("p", { staticClass: "requestDetail_p1" }, [
                      _vm._v("供应商：" + _vm._s(_vm.datas.companyName)),
                    ])
                  : _vm._e(),
                _vm.datas.payBankName
                  ? _c("p", { staticClass: "requestDetail_p1" }, [
                      _vm._v("收款方开户行：" + _vm._s(_vm.datas.payBankName)),
                    ])
                  : _vm._e(),
                _vm.datas.payBankNumber
                  ? _c("p", { staticClass: "requestDetail_p1" }, [
                      _vm._v("收款方账号：" + _vm._s(_vm.datas.payBankNumber)),
                    ])
                  : _vm._e(),
                _vm.datas.payName
                  ? _c("p", { staticClass: "requestDetail_p1" }, [
                      _vm._v("收款方名称：" + _vm._s(_vm.datas.payName)),
                    ])
                  : _vm._e(),
                _vm.datas.remark
                  ? _c("p", { staticClass: "requestDetail_p1" }, [
                      _vm._v("备注：" + _vm._s(_vm.datas.remark)),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "main", style: { height: _vm.mainHeight + "px" } },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "0.3rem" } },
                [
                  _vm.datas.requestType === 2
                    ? _c(
                        "div",
                        { staticClass: "card" },
                        _vm._l(_vm.datas.applies, function (items, indexs) {
                          return _c(
                            "van-collapse",
                            {
                              key: indexs,
                              attrs: { border: false },
                              model: {
                                value: _vm.activeNames,
                                callback: function ($$v) {
                                  _vm.activeNames = $$v
                                },
                                expression: "activeNames",
                              },
                            },
                            _vm._l(items.reApplyDetailList, function (item) {
                              return _c(
                                "van-collapse-item",
                                {
                                  key: item.id,
                                  attrs: {
                                    icon: "like-o",
                                    title: "费用名称：" + item.payName,
                                    name: item.id,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "goodsBox" }, [
                                    _c("p", { staticClass: "goodsBoxText1" }, [
                                      _vm._v("办公室：" + _vm._s(item.office)),
                                    ]),
                                    _c("p", { staticClass: "goodsBoxText1" }, [
                                      _vm._v("部门：" + _vm._s(item.dept)),
                                    ]),
                                    _c("p", { staticClass: "goodsBoxText1" }, [
                                      _c(
                                        "span",
                                        { staticClass: "goodsBoxText1" },
                                        [
                                          _vm._v("单价："),
                                          _c("span", { staticClass: "span2" }, [
                                            _vm._v(
                                              _vm._s(item.price) +
                                                _vm._s(item.currency)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "goodsBoxText1" },
                                        [
                                          _vm._v("数量："),
                                          _c("span", { staticClass: "span2" }, [
                                            _vm._v(_vm._s(item.number)),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "goodsBoxText1" },
                                        [
                                          _vm._v("总价："),
                                          _c("span", { staticClass: "span2" }, [
                                            _vm._v(
                                              _vm._s(
                                                item.price *
                                                  item.number *
                                                  item.exchange
                                              ) + "¥"
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                    item.remark
                                      ? _c(
                                          "p",
                                          { staticClass: "goodsBoxText1" },
                                          [
                                            _vm._v(
                                              "备注：" + _vm._s(item.remark)
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              )
                            }),
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._l(_vm.datas.orderChildrenList, function (items, indexs) {
                    return _c(
                      "div",
                      { key: indexs, staticClass: "card" },
                      [
                        _c("div", { staticClass: "cardTop" }, [
                          _c(
                            "div",
                            {
                              staticClass: "typeBtn",
                              staticStyle: { "background-color": "#5FCD72" },
                            },
                            [_vm._v(" 收入 ")]
                          ),
                          _c("p", { staticClass: "productName" }, [
                            _vm._v(_vm._s(items.resourceName)),
                          ]),
                          _c("span", { staticClass: "typeText" }, [
                            _vm._v(
                              _vm._s(
                                _vm.typeNemeList2[items.state]
                                  ? _vm.typeNemeList2[items.state].typeNameCn
                                  : items.stateName
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-left": "0.2rem",
                                "font-weight": "bold",
                                color: "#CE5F3A",
                              },
                            },
                            [_vm._v(_vm._s(_vm.datas.createName))]
                          ),
                        ]),
                        _c("div", { staticClass: "infoBox" }, [
                          _c(
                            "span",
                            {
                              staticClass: "orderNumber",
                              staticStyle: { "margin-right": "0.2rem" },
                            },
                            [
                              _vm._v(
                                "子订单号：" + _vm._s(items.orderChildrenId)
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "orderNumber",
                              staticStyle: { color: "#CE5F3A" },
                            },
                            [_vm._v("团号：" + _vm._s(items.planId))]
                          ),
                        ]),
                        _vm._l(
                          items.orderListDetailResultList,
                          function (item, index) {
                            return _c("div", { key: index }, [
                              _c("div", { staticClass: "goodsBox" }, [
                                _c("p", { staticClass: "goodsBoxText1" }, [
                                  _vm._v(_vm._s(item.productName) + " "),
                                  _c("span", [
                                    _vm._v(
                                      "(" +
                                        _vm._s(item.currency) +
                                        _vm._s(item.price) +
                                        "/" +
                                        _vm._s(item.priceCompany) +
                                        "/" +
                                        _vm._s(item.useTime) +
                                        ")"
                                    ),
                                  ]),
                                ]),
                                _c("p", { staticClass: "goodsBoxText2" }, [
                                  items.state === 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(item.startTime) +
                                            "-" +
                                            _vm._s(_vm.addOneDay(item.endTime))
                                        ),
                                      ])
                                    : item.startTime == item.endTime
                                    ? _c("span", [
                                        _vm._v(_vm._s(item.startTime)),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(item.startTime) +
                                            "-" +
                                            _vm._s(item.endTime)
                                        ),
                                      ]),
                                  _c("span", { staticClass: "span1" }, [
                                    _vm._v(
                                      _vm._s(item.number) +
                                        _vm._s(item.priceCompany) +
                                        _vm._s(
                                          _vm.days(
                                            item.startTime,
                                            item.endTime
                                          ) + 1
                                        ) +
                                        _vm._s(item.useTime)
                                    ),
                                  ]),
                                  _vm._v(" 总价："),
                                  _c("span", { staticClass: "span2" }, [
                                    _vm._v(_vm._s(_vm.totalPrice(item)) + "¥"),
                                  ]),
                                ]),
                              ]),
                              index !=
                              items.orderListDetailResultList.length - 1
                                ? _c("div", { staticClass: "line" })
                                : _vm._e(),
                            ])
                          }
                        ),
                      ],
                      2
                    )
                  }),
                ],
                2
              ),
              _vm._l(_vm.datas.procureChildrenList, function (items, indexs) {
                return _c(
                  "div",
                  { key: indexs, staticClass: "card" },
                  [
                    _c("div", { staticClass: "cardTop" }, [
                      _c("div", { staticClass: "typeBtn" }, [_vm._v(" 支出 ")]),
                      _c("p", { staticClass: "productName" }, [
                        _vm._v(_vm._s(items.resourceName)),
                      ]),
                      _c("span", { staticClass: "typeText" }, [
                        _vm._v(
                          _vm._s(
                            _vm.typeNemeList2[items.state]
                              ? _vm.typeNemeList2[items.state].typeNameCn
                              : items.stateName
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "infoBox" }, [
                      _c(
                        "span",
                        {
                          staticClass: "orderNumber",
                          staticStyle: { "margin-right": "0.2rem" },
                        },
                        [_vm._v("子订单号：" + _vm._s(items.orderChildrenId))]
                      ),
                      _c("span", { staticClass: "orderNumber" }, [
                        _vm._v("团号：" + _vm._s(items.planId)),
                      ]),
                    ]),
                    _vm._l(
                      items.orderListDetailResultList,
                      function (item, index) {
                        return _c("div", { key: index }, [
                          _c("div", { staticClass: "goodsBox" }, [
                            _c("p", { staticClass: "goodsBoxText1" }, [
                              _vm._v(_vm._s(item.productName) + " "),
                              _c("span", [
                                _vm._v(
                                  "(" +
                                    _vm._s(item.currency) +
                                    _vm._s(item.price) +
                                    "/" +
                                    _vm._s(item.priceCompany) +
                                    "/" +
                                    _vm._s(item.useTime) +
                                    ")"
                                ),
                              ]),
                            ]),
                            _c("p", { staticClass: "goodsBoxText2" }, [
                              items.state === 0
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(item.startTime) +
                                        "-" +
                                        _vm._s(_vm.addOneDay(item.endTime))
                                    ),
                                  ])
                                : item.startTime == item.endTime
                                ? _c("span", [_vm._v(_vm._s(item.startTime))])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(item.startTime) +
                                        "-" +
                                        _vm._s(item.endTime)
                                    ),
                                  ]),
                              _c("span", { staticClass: "span1" }, [
                                _vm._v(
                                  _vm._s(item.number) +
                                    _vm._s(item.priceCompany) +
                                    _vm._s(
                                      _vm.days(item.startTime, item.endTime) + 1
                                    ) +
                                    _vm._s(item.useTime)
                                ),
                              ]),
                              _vm._v(" 总价："),
                              _c("span", { staticClass: "span2" }, [
                                _vm._v(_vm._s(_vm.totalPrice(item)) + "¥"),
                              ]),
                            ]),
                          ]),
                          index != items.orderListDetailResultList.length - 1
                            ? _c("div", { staticClass: "line" })
                            : _vm._e(),
                        ])
                      }
                    ),
                  ],
                  2
                )
              }),
              _vm.imgList.length > 0
                ? _c(
                    "div",
                    { staticClass: "file" },
                    [
                      _c("p", { staticStyle: { "margin-bottom": "0.1rem" } }, [
                        _vm._v("图片："),
                      ]),
                      _c(
                        "van-grid",
                        { attrs: { border: false, "column-num": 3 } },
                        _vm._l(_vm.imgList, function (n, m) {
                          return _c(
                            "van-grid-item",
                            { key: m },
                            [
                              _c("van-image", {
                                attrs: {
                                  width: "100",
                                  height: "100",
                                  src: n.fileUrl,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerSeeImg(m)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.fileList.length > 0
                ? _c(
                    "div",
                    { staticClass: "file" },
                    [
                      _c("p", { staticStyle: { "margin-bottom": "0.1rem" } }, [
                        _vm._v("文件："),
                      ]),
                      _vm._l(_vm.fileList, function (n, m) {
                        return _c(
                          "a",
                          {
                            key: m,
                            staticClass: "fileLine",
                            attrs: { href: n.fileUrl, download: n.fileName },
                          },
                          [_vm._v(" " + _vm._s(n.fileName) + " ")]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "file" },
                _vm._l(_vm.paymentApprovalQueryResult.opList, function (n, m) {
                  return _c("p", { key: m }, [
                    _vm._v(" 审批人："),
                    _c("span", { staticStyle: { "margin-right": "0.15rem" } }, [
                      _vm._v(_vm._s(n.applyName)),
                    ]),
                    _vm._v(" 审批时间："),
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("dateFormatHms")(n.createTime))),
                    ]),
                  ])
                }),
                0
              ),
            ],
            2
          ),
          _c("div", { staticClass: "footer" }, [
            JSON.stringify(_vm.datas) !== "{}"
              ? _c("div", { staticStyle: { "margin-bottom": "0.3rem" } }, [
                  _c("div", { staticClass: "footerText1" }, [
                    _vm.receivable != "0.00"
                      ? _c("p", [
                          _vm._v(" 应收："),
                          _c("span", [_vm._v(_vm._s(_vm.receivable) + "¥")]),
                        ])
                      : _vm._e(),
                    _vm.payable != "0.00"
                      ? _c("p", [
                          _vm._v(" 应付："),
                          _c("span", [_vm._v(_vm._s(_vm.payable) + " ¥")]),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "footerText1" }, [
                    _vm.datas.totalIncomePrice
                      ? _c("p", [
                          _vm._v(" 实收："),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.datas.totalIncomePrice.toFixed(2)) +
                                " ¥"
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.datas.totalRequestPrice
                      ? _c("p", [
                          _vm._v(" 实付："),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.datas.totalRequestPrice.toFixed(2)) +
                                "¥"
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "footerText1" }, [
                    _vm.datas.requestType === 3
                      ? _c("p", [
                          _vm._v(" 已借款金额："),
                          _c("span", [
                            _vm._v(_vm._s(_vm.borrowedAmount.toFixed(2)) + "¥"),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.datas.borrowPrice
                      ? _c("p", [
                          _vm._v(" 已抵扣借款金额："),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.datas.borrowPrice.toFixed(2)) + "¥"
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "footerText1" }, [
                    _vm.datas.borrowPrice
                      ? _c("p", [
                          _vm._v(" 实际需付款金额："),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                (
                                  _vm.datas.price - _vm.datas.borrowPrice
                                ).toFixed(2)
                              ) + "¥"
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
            _vm.datas.status !== 5
              ? _c("div", [
                  _vm.paymentApprovalQueryResult.schedule === 1
                    ? _c(
                        "div",
                        { staticClass: "footerBtnBox" },
                        [
                          _c(
                            "van-button",
                            {
                              staticClass: "footerBtns",
                              staticStyle: { "margin-right": "0.2rem" },
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.businessOpSaveH5 },
                            },
                            [_vm._v("审核通过")]
                          ),
                          _c(
                            "van-button",
                            {
                              staticClass: "footerBtns",
                              attrs: { size: "small", type: "danger" },
                              on: { click: _vm.rejectClick },
                            },
                            [_vm._v("驳回")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.paymentApprovalQueryResult.schedule === 2
                    ? _c(
                        "div",
                        { staticClass: "footerBtnBox" },
                        [
                          _c(
                            "van-button",
                            {
                              staticClass: "footerBtns",
                              attrs: { size: "small", type: "primary" },
                            },
                            [_vm._v("审核已通过")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.paymentApprovalQueryResult.schedule === 3
                    ? _c(
                        "div",
                        { staticClass: "footerBtnBox1" },
                        [
                          _c(
                            "van-button",
                            {
                              staticClass: "footerBtns",
                              attrs: { size: "small", color: "#627177" },
                            },
                            [_vm._v("审核已驳回")]
                          ),
                          _c("p", [
                            _vm._v(
                              "驳回理由：" +
                                _vm._s(
                                  _vm.paymentApprovalQueryResult
                                    .reasonForRejection
                                )
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _c(
                  "div",
                  {
                    staticStyle: {
                      color: "red",
                      "text-align": "center",
                      "font-size": "0.35rem",
                      "font-weight": "bold",
                    },
                  },
                  [_vm._v(" 该提请已作废无需再审批 ")]
                ),
          ]),
          _c(
            "van-dialog",
            {
              attrs: { title: "驳回理由", "show-cancel-button": "" },
              on: { confirm: _vm.confirm, cancel: _vm.cancel },
              model: {
                value: _vm.show,
                callback: function ($$v) {
                  _vm.show = $$v
                },
                expression: "show",
              },
            },
            [
              _c("van-field", {
                attrs: { placeholder: "请输入驳回理由" },
                model: {
                  value: _vm.chunDatas.returnMsg,
                  callback: function ($$v) {
                    _vm.$set(_vm.chunDatas, "returnMsg", $$v)
                  },
                  expression: "chunDatas.returnMsg",
                },
              }),
            ],
            1
          ),
          _c(
            "van-dialog",
            {
              attrs: { title: "审批", "show-cancel-button": "" },
              on: { confirm: _vm.optionClick },
              model: {
                value: _vm.show1,
                callback: function ($$v) {
                  _vm.show1 = $$v
                },
                expression: "show1",
              },
            },
            [
              _vm.show1
                ? _c("div", { staticClass: "shenheBox" }, [
                    JSON.stringify(_vm.datas) !== "{}" &&
                    _vm.datas.totalRequestPrice
                      ? _c(
                          "div",
                          { staticClass: "shenheLine" },
                          [
                            _c("van-icon", {
                              staticStyle: { "margin-right": "0.1rem" },
                              attrs: { color: "#ED7046", name: "balance-o" },
                            }),
                            _c("div", { staticClass: "typeW" }, [
                              _vm._v("提请金额："),
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.datas.price) +
                                  _vm._s(_vm.datas.currency)
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }