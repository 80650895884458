<!-- 应付款审批 -->
<template>
    <div class='container' v-if="datas">
        <van-nav-bar title="应付款审批"> </van-nav-bar>
        <div class="header">
            <div class="mainTop">
                本次<span v-if="datas.requestType === 3">借款</span>提请金额：{{ datas.price }}{{ datas.currency }}
                <span style="font-size: 0.2rem;">({{ numberToChinese(datas.price) }})</span>
                <p v-if="datas.currency !== '¥'">提请原币金额：{{ datas.yuanBiPrice }}{{ datas.currency }} <span
                        style="font-size: 0.2rem;">({{ numberToChinese(datas.yuanBiPrice) }})</span></p>
            </div>
            <div style="margin-bottom: 0.1rem;" class="requestDetail card">
                <p v-if="datas.id" class="requestDetail_p1">提请编号：{{ datas.id }}</p>
                <p v-if="datas.createName" class="requestDetail_p1">提请人：{{ datas.createName }}</p>
                <p v-if="datas.companyName" class="requestDetail_p1">供应商：{{ datas.companyName }}</p>
                <p v-if="datas.payBankName" class="requestDetail_p1">收款方开户行：{{ datas.payBankName }}</p>
                <p v-if="datas.payBankNumber" class="requestDetail_p1">收款方账号：{{ datas.payBankNumber }}</p>
                <p v-if="datas.payName" class="requestDetail_p1">收款方名称：{{ datas.payName }}</p>
                <p v-if="datas.remark" class="requestDetail_p1">备注：{{ datas.remark }}</p>
            </div>
        </div>
        <div class="main" :style="{ height: mainHeight + 'px' }">
            <div style="margin-bottom: 0.3rem;">
                <div class="card" v-if="datas.requestType === 2">
                    <van-collapse :border="false" v-model="activeNames" v-for="(items, indexs) in datas.applies"
                        :key="indexs">
                        <van-collapse-item v-for="(item) in items.reApplyDetailList" :key="item.id" icon="like-o"
                            :title="'费用名称：' + item.payName" :name="item.id">
                            <div class="goodsBox">
                                <p class="goodsBoxText1">办公室：{{ item.office }}</p>
                                <p class="goodsBoxText1">部门：{{ item.dept }}</p>
                                <p class="goodsBoxText1">
                                    <span class="goodsBoxText1">单价：<span class="span2">{{ item.price }}{{ item.currency
                                            }}</span></span>
                                    <span class="goodsBoxText1">数量：<span class="span2">{{ item.number }}</span></span>
                                    <span class="goodsBoxText1">总价：<span class="span2">{{
                                        item.price * item.number * item.exchange }}¥</span></span>
                                </p>
                                <p v-if="item.remark" class="goodsBoxText1">备注：{{ item.remark }}</p>
                            </div>
                        </van-collapse-item>
                    </van-collapse>
                </div>
                <div class="card" v-for="(items, indexs) in datas.orderChildrenList" :key="indexs">
                    <div class="cardTop">
                        <div class="typeBtn" style="background-color: #5FCD72;">
                            收入
                        </div>
                        <p class="productName">{{ items.resourceName }}</p>
                        <span class="typeText">{{ typeNemeList2[items.state] ? typeNemeList2[items.state].typeNameCn :
                            items.stateName }}</span> <span
                            style="margin-left: 0.2rem;font-weight: bold;color:#CE5F3A;">{{ datas.createName }}</span>
                    </div>
                    <div class="infoBox">
                        <span class="orderNumber" style="margin-right: 0.2rem;">子订单号：{{ items.orderChildrenId }}</span>
                        <span class="orderNumber" style="color:#CE5F3A;">团号：{{ items.planId }}</span>
                    </div>
                    <div v-for="(item, index) in items.orderListDetailResultList" :key="index">
                        <div class="goodsBox">
                            <p class="goodsBoxText1">{{ item.productName }}
                                <span>({{ item.currency }}{{ item.price }}/{{ item.priceCompany }}/{{ item.useTime
                                    }})</span>
                            </p>
                            <p class="goodsBoxText2">
                                <span v-if="items.state === 0">{{ item.startTime }}-{{ addOneDay(item.endTime) }}</span>
                                <span v-else-if="item.startTime == item.endTime">{{ item.startTime }}</span>
                                <span v-else>{{ item.startTime }}-{{ item.endTime }}</span>
                                <span class="span1">{{ item.number }}{{
                                    item.priceCompany }}{{ days(item.startTime, item.endTime) + 1 }}{{
                                        item.useTime }}</span> 总价：<span class="span2">{{ totalPrice(item) }}¥</span>
                            </p>
                        </div>
                        <div class="line" v-if="index != items.orderListDetailResultList.length - 1"></div>
                    </div>
                </div>
            </div>
            <div class="card" v-for="(items, indexs) in datas.procureChildrenList" :key="indexs">
                <div class="cardTop">
                    <div class="typeBtn">
                        支出
                    </div>
                    <p class="productName">{{ items.resourceName }}</p>
                    <span class="typeText">{{ typeNemeList2[items.state] ? typeNemeList2[items.state].typeNameCn :
                        items.stateName }}</span>
                </div>
                <div class="infoBox">
                    <span class="orderNumber" style="margin-right: 0.2rem;">子订单号：{{ items.orderChildrenId }}</span>
                    <span class="orderNumber">团号：{{ items.planId }}</span>
                </div>
                <div v-for="(item, index) in items.orderListDetailResultList" :key="index">
                    <div class="goodsBox">
                        <p class="goodsBoxText1">{{ item.productName }}
                            <span>({{ item.currency }}{{ item.price }}/{{ item.priceCompany }}/{{ item.useTime
                                }})</span>
                        </p>
                        <p class="goodsBoxText2">
                            <span v-if="items.state === 0">{{ item.startTime }}-{{ addOneDay(item.endTime) }}</span>
                            <span v-else-if="item.startTime == item.endTime">{{ item.startTime }}</span>
                            <span v-else>{{ item.startTime }}-{{ item.endTime }}</span>
                            <span class="span1">{{ item.number }}{{
                                item.priceCompany }}{{ days(item.startTime, item.endTime) + 1 }}{{ item.useTime
                                }}</span> 总价：<span class="span2">{{
                                    totalPrice(item) }}¥</span>
                        </p>
                    </div>
                    <div class="line" v-if="index != items.orderListDetailResultList.length - 1"></div>
                </div>
            </div>
            <div class="file" v-if="imgList.length > 0">
                <p style="margin-bottom: 0.1rem;">图片：</p>
                <van-grid :border="false" :column-num="3">
                    <van-grid-item v-for="(n, m) in imgList" :key="m">
                        <van-image @click="handlerSeeImg(m)" width="100" height="100" :src="n.fileUrl" />
                    </van-grid-item>
                </van-grid>
            </div>
            <div class="file" v-if="fileList.length > 0">
                <p style="margin-bottom: 0.1rem;">文件：</p>
                <a class="fileLine" :href="n.fileUrl" :download="n.fileName" v-for="(n, m) in fileList" :key="m">
                    {{ n.fileName }}
                </a>
                <!-- <a class="fileLine" @click="downloadFile(n.fileUrl, n.fileName)" v-for="(n, m) in fileList" :key="m">
                    {{ n.fileName }}
                </a> -->
            </div>
            <div class="file">
                <p v-for="(n, m) in paymentApprovalQueryResult.opList" :key="m"> 审批人：<span
                        style="margin-right: 0.15rem;">{{ n.applyName }}</span> 审批时间：<span>{{ n.createTime |
                            dateFormatHms
                        }}</span></p>
            </div>
        </div>
        <div class="footer">
            <div style="margin-bottom: 0.3rem;" v-if="JSON.stringify(datas) !== '{}'">
                <div class="footerText1">
                    <p v-if="receivable != '0.00'"> 应收：<span>{{ receivable }}¥</span></p>
                    <p v-if="payable != '0.00'"> 应付：<span>{{ payable }} ¥</span></p>
                </div>
                <!-- 得自己算 -->
                <div class="footerText1">
                    <p v-if="datas.totalIncomePrice"> 实收：<span>{{ datas.totalIncomePrice.toFixed(2) }} ¥</span></p>
                    <p v-if="datas.totalRequestPrice"> 实付：<span>{{ datas.totalRequestPrice.toFixed(2) }}¥</span></p>
                </div>
                <div class="footerText1">
                    <p v-if="datas.requestType === 3"> 已借款金额：<span>{{ borrowedAmount.toFixed(2) }}¥</span></p>
                    <p v-if="datas.borrowPrice"> 已抵扣借款金额：<span>{{ datas.borrowPrice.toFixed(2) }}¥</span></p>
                </div>
                <div class="footerText1">
                    <p v-if="datas.borrowPrice"> 实际需付款金额：<span>{{ (datas.price - datas.borrowPrice).toFixed(2)
                            }}¥</span>
                    </p>
                </div>
            </div>
            <div v-if="datas.status !== 5">
                <div class="footerBtnBox" v-if="paymentApprovalQueryResult.schedule === 1">
                    <van-button class="footerBtns" @click="businessOpSaveH5" style="margin-right: 0.2rem;" size="small"
                        type="primary">审核通过</van-button>
                    <van-button @click="rejectClick" class="footerBtns" size="small" type="danger">驳回</van-button>
                </div>
                <div v-if="paymentApprovalQueryResult.schedule === 2" class="footerBtnBox">
                    <van-button class="footerBtns" size="small" type="primary">审核已通过</van-button>
                </div>
                <div v-if="paymentApprovalQueryResult.schedule === 3" class="footerBtnBox1">
                    <van-button class="footerBtns" size="small" color="#627177">审核已驳回</van-button>
                    <p>驳回理由：{{ paymentApprovalQueryResult.reasonForRejection }}</p>
                </div>
            </div>
            <div v-else style="color: red;text-align: center;font-size: 0.35rem;font-weight: bold;">
                该提请已作废无需再审批
            </div>
        </div>
        <van-dialog @confirm="confirm" @cancel="cancel" v-model="show" title="驳回理由" show-cancel-button>
            <van-field v-model="chunDatas.returnMsg" placeholder="请输入驳回理由" />
        </van-dialog>
        <van-dialog @confirm="optionClick" v-model="show1" title="审批" show-cancel-button>
            <div class="shenheBox" v-if="show1">
                <div class="shenheLine" v-if="JSON.stringify(datas) !== '{}' && datas.totalRequestPrice">
                    <van-icon color="#ED7046" style="margin-right: 0.1rem;" name="balance-o" />
                    <div class="typeW">提请金额：</div> <span>{{ datas.price }}{{ datas.currency }}</span>
                </div>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import OrderApi from "@/components/Api/order";
import ApplyApi from "@/components/Api/apply";
import ResourceApi from '@/components/Api/resource';
import { Toast } from 'vant';
import { days, getUrlParams, typeNemeList2, addOneDay, numberToChinese } from '@/utils/unit'
export default {
    components: {
    },
    data() {
        return {
            numberToChinese,
            activeNames: [],
            imgList: [],//图片列表
            fileList: [],//文件列表
            mainHeight: 0,
            datas: {},//渲染的数据
            addOneDay,
            days,
            show: false,
            show1: false,
            typeNemeList2,
            borrowedAmount: 0,
            paymentApprovalQueryResult: {},//支付审批查询结果
            chunDatas: {
                returnMsg: ''
            }
        }
    },
    computed: {
        //计算总价钱
        totalPrice() {
            return function (n) {
                let price = 0
                price += n.price * n.number * n.rate * (this.days(n.startTime, n.endTime) + 1)
                return price.toFixed(2)
            }
        },
        //应收
        receivable() {
            let price = 0
            this.datas.orderChildrenList.forEach(item => {
                item.orderListDetailResultList.forEach(n => {
                    price += Number(this.totalPrice(n))
                })
            })
            return price.toFixed(2)
        },
        //应付
        payable() {
            let price = 0
            this.datas.procureChildrenList.forEach(item => {
                item.orderListDetailResultList.forEach(n => {
                    price += Number(this.totalPrice(n))
                })
            })
            return price.toFixed(2)
        }
    },
    watch: {},
    methods: {
        downloadFile(url, fileName) {
            window.open(url);
            // let download = new XMLHttpRequest();
            // download.open("GET", url, true);
            // download.responseType = 'blob';
            // download.onload = function (e) {
            //     let url = window.URL.createObjectURL(download.response)
            //     let a = document.createElement('a');
            //     a.href = url
            //     a.download = fileName;
            //     a.click()
            // }
        },
        isImage(fileName) {
            let fileExtension = fileName.split('.').pop().toLowerCase();
            let imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
            return imageExtensions.includes(fileExtension);
        },
        getFile(parentId) {
            this.imgList = []
            this.fileList = []
            new ResourceApi("resource").queryAllByParentId({ parentId }).then(res => {
                if (res.status === 200) {
                    res.data.forEach(item => {
                        if (this.isImage(item.fileName)) {
                            this.imgList.push(item)
                        } else {
                            this.fileList.push(item)
                        }
                    })
                }
            })
        },
        getInfo() {
            let obj = {
                "bid": this.chunDatas.orderMainId,
                "configId": this.chunDatas.configId,
                "userId": this.chunDatas.userId
            }
            new ApplyApi("apply").approveInfo(obj).then(res => {
                if (res.status === 200) {
                    this.paymentApprovalQueryResult = res.data
                    setTimeout(() => {
                        const footerHeight = this.$el.querySelector('.footer').clientHeight;
                        const headerHeight = this.$el.querySelector('.header').clientHeight;
                        this.mainHeight = window.innerHeight - footerHeight - headerHeight - 50;
                    })
                }
            })
        },
        getDatas() {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
            });
            new OrderApi("order").payRequestDetail(this.chunDatas.orderMainId).then(res => {
                if (res.status == 200) {
                    this.getFile(res.data.id)
                    this.datas = res.data
                    if (this.datas) {
                        this.getInfo()
                        if (this.datas.requestType === 3) {
                            this.selectCompanyBorrow(this.datas.companyId)
                        }
                        if (this.datas.requestType === 2) {
                            this.datas.applies.forEach(item => {
                                this.activeNames = [...this.activeNames, ...item.reApplyDetailList.map(n => n.id)]
                            })
                        }
                    }
                    Toast.clear();
                } else {
                    Toast(res.statusText);
                }
            })
        },
        selectCompanyBorrow(companyId) {
            new OrderApi("order").selectCompanyBorrowList(companyId).then(res => {
                if (res.status == 200) {
                    res.data.forEach(item => {
                        this.borrowedAmount += (item.price * item.rate)
                    })
                }
            })
        },
        optionClick() {
            new ApplyApi("apply").businessOpSaveH5(this.chunDatas).then(res => {
                Toast.loading({
                    message: '审批中...',
                    forbidClick: true,
                });
                if (res.status === 200) {
                    Toast("审批成功...");
                    this.getDatas()
                } else {
                    Toast(res.statusText);
                }
            })
        },
        //确认
        confirm() {
            new ApplyApi("apply").businessOpRejectH5(this.chunDatas).then(res => {
                Toast.loading({
                    message: '驳回中...',
                    forbidClick: true,
                });
                if (res.status === 200) {
                    Toast("驳回成功...");
                    this.getDatas()
                } else {
                    Toast(res.statusText);
                }
            })
        },
        //驳回
        rejectClick() {
            this.show = true
        },
        businessOpSaveH5() {
            this.show1 = true
        },

        //取消
        cancel() {
        },
        //照片查看
        handlerSeeImg(ins) {
            let arr = this.imgList.map(n => n.fileUrl)
            this.$viewerApi({
                options: {
                    initialViewIndex: ins
                },
                images: arr,
            })
        },
    },
    created() {

    },
    mounted() {
        this.chunDatas = {
            orderMainId: getUrlParams('reId'),
            configId: getUrlParams('configId'),
            userId: getUrlParams('userId'),
            step: getUrlParams('step'),
            approvalBusinessId: getUrlParams('approvalBusinessId'),
        }
        this.getDatas()
    },
}
</script>
<style lang='scss' scoped>
/deep/ .van-grid-item__content {
    padding: 0 !important;
}

.container {
    .line {
        margin: 0.2rem 0;
        height: 1px;
        width: 100%;
        border-bottom: 1px solid #EBEDF0;
    }

    .shenheBox {
        padding: 0.3rem;

        .shenheLine {
            display: flex;
            align-items: center;
            margin-bottom: 0.1rem;

            .typeW {
                font-size: 0.27rem;
                color: #000;
                width: 1.8rem;
            }

            span {
                color: red;
                font-size: 0.27rem;
            }
        }
    }

    .header {
        .mainTop {
            font-size: 0.34rem;
            color: red;
            font-weight: bold;
            padding: 0.2rem 0.2rem 0 0.2rem;
        }

        .requestDetail {
            padding: 0.2rem;

            p {
                font-size: 0.26rem;
            }
        }
    }

    .card {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        padding: 0.2rem;
        border-radius: 0.1rem;
        margin-bottom: 0.3rem;

        .cardTop {
            display: flex;
            align-items: center;
            margin-bottom: 0.1rem;

            .typeBtn {
                width: 0.8rem;
                height: 0.4rem;
                line-height: 0.4rem;
                border-radius: 0.08rem;
                background-color: #DE562C;
                color: white;
                font-size: 0.16rem;
                text-align: center;
                font-family: PingFang SC;
                margin-right: 0.12rem;
            }

            .typeText {
                color: #DE562C;
                margin-left: 0.1rem;
            }

            .productName {
                color: #006FFF;
                font-size: 0.27rem;
                font-family: SourceHanSansSC-bold;
            }


        }

        .infoBox {
            margin-bottom: 0.2rem;

            .infoBoxCom {
                font-size: 0.35rem;
                font-family: cursive;
                font-weight: bold;
                margin-bottom: 0.1rem;
            }

            .orderNumber {
                font-size: 0.25rem;
                font-family: SourceHanSansSC-bold;
                font-weight: bold;
            }
        }

        .goodsBox {
            .goodsBoxText1 {
                color: #333333;
                font-size: 0.27rem;
                font-family: SourceHanSansSC-bold;
                font-weight: bold;
                margin-bottom: 0.1rem;

                span {
                    font-size: 0.25rem;
                    margin-left: 0.1rem;
                    color: #CE5F3A;
                }
            }

            .goodsBoxText2 {
                color: #333333;
                font-size: 0.27rem;
                font-family: SourceHanSansSC-bold;
                font-weight: bold;

                span {
                    font-size: 0.25rem;
                }

                .span1 {
                    color: #CE5F3A;
                    margin-left: 0.1rem;
                }

                .span2 {
                    color: red;
                    font-size: 0.27rem;
                }
            }
        }
    }

    .main {
        padding: 0.2rem;
        overflow-y: scroll;

        .file {
            margin-bottom: 0.2rem;

            .fileLine {
                display: block;
                text-decoration: underline;
            }

            span {
                color: #000;
                font-size: 0.3rem;
                font-weight: bold;
            }
        }
    }

    .footer {
        padding: 0.35rem 0.35rem 0.15rem 0.35rem;
        width: 100%;

        .footerText1 {
            color: #333333;
            font-size: 0.26rem;
            font-family: SourceHanSansSC-bold;
            font-weight: bold;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            span {
                color: red;
            }
        }

        .footerBtnBox {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .footerBtns {
                width: 100%;
            }
        }

        .footerBtnBox1 {
            display: flex;
            align-items: center;
            flex-direction: column;

            .footerBtns {
                margin-bottom: 0.1rem;
                width: 100%;
            }
        }
    }
}
</style>